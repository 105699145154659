import React from 'react';
import { Form } from 'react-bootstrap';
import StringToHtml from '../StringToHtml';

type Props = {
  inputId?: string;
  label?: React.ReactNode;
  required?: boolean;
  hint?: React.ReactNode;
  className?: string;
};

const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

const renderLabel = (label: React.ReactNode): React.ReactNode => {
  if (isString(label)) {
    return <StringToHtml text={label} />;
  }
  return label ?? <>&nbsp;</>;
};

const InputLabel: React.FC<Props> = ({
  inputId,
  label,
  required,
  hint,
  className,
}) => (
  <Form.Label htmlFor={inputId} className={className}>
    {required && <abbr className="text-danger me-1">*</abbr>}
    {renderLabel(label)}
    {hint && <span> {hint}</span>}
  </Form.Label>
);

export default InputLabel;
