import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Column, Row, SortingRule } from 'react-table';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { parseDate } from '../../../../../utils/dateUtils';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import { EmployeeCell } from '../Common/EmployeeCell';
import { Workflow, WorkflowEmployee, WorkflowStepStatusInfo } from '../types';
import { WorkflowEmployeeHint } from '../Common/WorkflowEmployeeHint';
import { WorkflowStepsProgress } from '../Common/WorkflowStepsProgress';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { employeeSort } from '../Common/sorting';
import { TableContentManual } from '../../../manager/components/TableContentManual';
import { WorkflowType } from '../../../__generated__/graphql';
import { InfoHint } from '../../../../../components/hint/InfoHint';

type Props<T> = {
  employeeColumnVisible: boolean;
  workflows: Workflow[];
  onItemClick: (workflowId: string) => void;
  initialSortBy: Array<SortingRule<T>>;
  onSortByChanged: (sortBy: SortingRule<T>[]) => void;
  pageSize?: number;
  onPageIndexChanged?: (pageIndex: number) => void;
  itemsCount: number | undefined;
  initialPageIndex: number;
};

type TableItem = {
  id: string;
  name: string;
  employee: WorkflowEmployee;
  workflowType: WorkflowType;
  dueDate: string | null;
  stepStatuses: WorkflowStepStatusInfo[];
};

export const WorkflowsTable: React.FC<Props<Workflow>> = ({
  workflows,
  employeeColumnVisible,
  onItemClick,
  pageSize,
  itemsCount,
  initialPageIndex,
  onPageIndexChanged,
  onSortByChanged,
  initialSortBy,
}) => {
  const { t } = useTranslation();

  const handleOnRowClick = useCallback(
    (item: TableItem) => {
      onItemClick(item.id);
    },
    [onItemClick],
  );

  const items = workflows.map(mapToTableItem);

  const columns: Column<TableItem>[] = useMemo(() => {
    const columnsWithNull: (Column<TableItem> | null)[] = [
      {
        Header: () => <Trans t={t} i18nKey="workflows.table.header.workflow" />,
        accessor: 'name',
        Cell: ({ value, row }: { value: string; row: Row<TableItem> }) => (
          <div>
            <a
              href="stub"
              onClick={(e) => {
                e.preventDefault();
              }}>
              {value}
            </a>
            <WorkflowEmployeeHint
              className="ms-1"
              workflowType={row.original.workflowType}
              employee={row.original.employee}
            />
          </div>
        ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="workflows.table.header.workflowType" />
        ),
        accessor: 'workflowType',
        Cell: ({ value }: { value: WorkflowEmployee }) => {
          return <Trans t={t} i18nKey={`workflows.workflowTypes.${value}`} />;
        },
      },
      employeeColumnVisible
        ? {
            Header: () => (
              <Trans t={t} i18nKey="workflows.table.header.workflowAssignee" />
            ),
            accessor: 'employee',
            Cell: ({ value }: { value: WorkflowEmployee }) => {
              return (
                <EmployeeCell
                  name={value.name}
                  avatarUrl={value.avatarUrl}
                  position={value.position ?? undefined}
                  department={value.department ?? undefined}
                />
              );
            },
            sortType: (rowA: Row<TableItem>, rowB: Row<TableItem>) =>
              employeeSort(rowA.original.employee, rowB.original.employee),
          }
        : null,
      {
        Header: () => (
          <div>
            {t('workflows.table.header.dueDate')}
            <InfoHint
              className={'ms-1'}
              text={t('workflows.table.header.dueDateTooltip')}
            />
          </div>
        ),
        accessor: 'dueDate',
        Cell: ({ value }: { value: string | null }) =>
          value ? (
            <FormattedDate day={parseDate(value)} format="short" />
          ) : (
            <span>-</span>
          ),
      },
      {
        Header: () => <Trans t={t} i18nKey="workflows.table.header.status" />,
        accessor: 'stepStatuses',
        Cell: ({ value }: { value: WorkflowStepStatusInfo[] }) => (
          <WorkflowStepsProgress steps={value} />
        ),
        disableSortBy: true,
      },
    ];
    return columnsWithNull.filter((x): x is Column<TableItem> => x !== null);
  }, [t, employeeColumnVisible]);

  if (!items.length) {
    return <EmptyWorkflows />;
  }

  return (
    <TableContentManual
      columns={columns}
      data={items}
      onRowClick={handleOnRowClick}
      getRowProps={() => ({ role: 'button' })}
      pageSize={pageSize}
      itemsCount={itemsCount}
      initialSortBy={initialSortBy}
      onSortByChanged={onSortByChanged}
      initialPageIndex={initialPageIndex}
      onPageIndexChanged={onPageIndexChanged}
    />
  );
};

const EmptyWorkflows: React.FC = () => {
  const t = useNamespacedTranslation('workflows.table');
  return <EmptyStateCardBody title={t('empty')} />;
};

function mapToTableItem(src: Workflow): TableItem {
  return {
    id: src.id,
    name: src.name,
    employee: src.employee,
    workflowType: src.workflowType,
    dueDate: src.closestItemDueDate,
    stepStatuses: src.stepStatusInfos,
  };
}
