import React, { Dispatch, SetStateAction } from 'react';
import { ApolloError } from '@apollo/client';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { TicketsSidebarLoading } from './TicketsSidebarLoading';
import ModalSidebar from '../../../../components/ModalSidebar';
import Skeleton from 'react-loading-skeleton';
import { Tab, Tabs } from 'react-bootstrap';
import { sidebarTabKeys as tabKeys } from '../../common';
import CommentListWrapper from '../../../../components/Comment/CommentListWrapper/CommentListWrapper';
import { RelatedObjectNames } from '../../../../components/Comment/types';
import ServerError from '../../../../../../components/ServerError';
import { Ticket } from './types';
import { TicketsInformationTab } from './TicketsInformationTab/TicketsInformationTab';

type Props = {
  visible: boolean;
  onClose: () => void;
  ticket: Ticket | null;
  recordId: string;
  isLoading: boolean;
  error?: ApolloError;
  isManager: boolean;
  onTicketClose: (id: string) => void;
  selectedTab: string | null;
  setSelectedTab: Dispatch<SetStateAction<string | null>>;
};

const i18Prefix = 'support.tickets.sidebarModal.tabs';
export const TicketsSidebar: React.FC<Props> = ({
  visible,
  onClose,
  recordId,
  ticket,
  isLoading,
  error,
  isManager,
  onTicketClose,
  selectedTab,
  setSelectedTab,
}) => {
  const t = useNamespacedTranslation(i18Prefix);

  const handleClose = () => {
    onClose();
  };

  const isSelectedTab = (tabKey: string) => tabKey === selectedTab;

  const isCommentsEnabled: boolean = !ticket?.anonymous;

  const renderContent = () => {
    if (isLoading || !ticket) {
      return (
        <ModalSidebar
          show={visible}
          onClose={handleClose}
          header={<HeaderLoader />}
          content={<TicketsSidebarLoading />}></ModalSidebar>
      );
    }

    return (
      <ModalSidebar
        show={visible}
        onClose={handleClose}
        header={ticket.subject.label}
        content={
          <Tabs
            className="mx-4"
            onSelect={setSelectedTab}
            activeKey={selectedTab ?? tabKeys.INFORMATION}>
            <Tab
              className="m-4 mt-4"
              eventKey={tabKeys.INFORMATION}
              title={t('information')}>
              <TicketsInformationTab
                ticket={ticket}
                isManager={isManager}
                onTicketClose={onTicketClose}
              />
            </Tab>
            {isCommentsEnabled && (
              <Tab
                eventKey={tabKeys.COMMENTS}
                title={t('comments')}
                className="h-100 comments-tab">
                {isSelectedTab(tabKeys.COMMENTS) && (
                  <CommentListWrapper
                    recordId={recordId}
                    relatedObjectName={RelatedObjectNames.Ticket}
                  />
                )}
              </Tab>
            )}
          </Tabs>
        }></ModalSidebar>
    );
  };

  if (error) {
    return <ServerError />;
  }

  return renderContent();
};

const HeaderLoader = () => {
  return <Skeleton width={100} />;
};
