import React, { useEffect, useMemo } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { routes as authRoutes } from '../../../auth/routes';
import { useUserInfo } from '../../context/UserInfo';

const INACTIVITY_DEFAULT_TIMEOUT_MINUTES = 15;

export const AutoLogout: React.FC = () => {
  const { logout } = useAuth();
  const history = useHistory();
  const { inactivityTimeout: inactivityTimeoutSf } = useUserInfo();

  const inactivityTimeoutMinutes: number | null = useMemo(() => {
    // Disable auto-logout in development mode
    if (import.meta.env.MODE !== 'production') {
      return null;
    }
    // Zero and negative values disable auto-logout
    if (inactivityTimeoutSf !== null && inactivityTimeoutSf <= 0) {
      return null;
    }
    // If SF value is not set then use default value
    return inactivityTimeoutSf ?? INACTIVITY_DEFAULT_TIMEOUT_MINUTES;
  }, [inactivityTimeoutSf]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const resetTimeout = () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (inactivityTimeoutMinutes !== null) {
        timeout = setTimeout(() => {
          logout();
          history.push(authRoutes.sessionTimeout.route.create({}));
        }, inactivityTimeoutMinutes * 60 * 1000);
      }
    };

    const handleActivity = () => {
      resetTimeout();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    resetTimeout();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [logout, history, inactivityTimeoutMinutes]);

  return null;
};
