import moment from 'moment';
import React, { useEffect } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { useCurrentLocale } from '../../../../context/I18n';
import {
  ManagerRole,
  useIdentifyMutation,
  UserInfoQuery,
  useUserInfoQuery,
} from '../../__generated__/graphql';
import { identify as segmentIdentify } from '../Analytics/index';
import { Loading } from './Loading';
import { getEmployeeInitials } from '../../utils/employeeInitials';
import { getUserStorageKey } from '../../hooks/useLocalStorage';
import { MANAGER_MODE_LS_KEY } from '../../hooks/useIsManagerMode';
import { useTranslation } from 'react-i18next';
import { isEqualAlphanumerically } from '../../../../utils/string';
import { UserInfo } from './types';

const UserInfoContext = React.createContext<UserInfo>({
  id: '',
  startDate: null,
  endDate: null,
  name: '',
  inactivityTimeout: null,
  firstName: '',
  logoUrl: null,
  calendarToken: null,
  lastName: '',
  email: '',
  position: null,
  locationId: null,
  locationName: null,
  departmentName: null,
  managerName: null,
  isManagerModeAvailable: false,
  isHiringManager: false,
  managerRoles: [],
  isActive: false,
  hasLeftCompany: false,
  hasSlackEnabled: false,
  initials: '',
  avatarUrl: null,
  canChangeOwnTimeEntries: false,
  coverImageUrl: null,
  organizationsFeatures: [],
  organisation: '',
  teams: [],
});

const isActive = (me: UserInfoQuery['me']): boolean => {
  const today = moment();

  if (
    !me.flair__Start_Date__c ||
    moment(me.flair__Start_Date__c).isAfter(today, 'day')
  ) {
    return false;
  }

  if (
    me.flair__End_Date__c &&
    moment(me.flair__End_Date__c).isBefore(today, 'day')
  ) {
    return false;
  }

  return true;
};

const LAST_LOGGED_EMPLOYEE_ID = 'last_logged_employee_id';

export const UserInfoProvider: React.FC = (props) => {
  const { loading, error, data } = useUserInfoQuery();
  const [identify] = useIdentifyMutation();
  const locale = useCurrentLocale();
  const { logout } = useAuth();
  const { i18n } = useTranslation();

  const isManagerModeOn = getIsManagerModeOn();

  useEffect(() => {
    if (
      data &&
      data.me.flair__First_Name__c &&
      data.me.flair__Company_Email__c
    ) {
      segmentIdentify({
        id: data.me.Id,
        locale: locale,
        organization: data.me.Organization,
        manager: data.me.managerRoles.length > 0,
      });

      identify({
        variables: {
          input: {
            id: data.me.Id,
            firstName: data.me.flair__First_Name__c,
            lastName: data.me.flair__Last_Name__c || '',
            email: data.me.flair__Company_Email__c,
            locale: locale,
            organization: data.me.Organization,
            manager: data.me.managerRoles.length > 0,
          },
        },
      }).catch(() => {});
    }
    if (data?.me.Id?.length) {
      localStorage.setItem(LAST_LOGGED_EMPLOYEE_ID, data.me.Id);
    }
  }, [data, locale, identify]);

  useEffect(() => {
    if (error) {
      logout();
    }
  }, [error, logout]);

  const employeeLanguage = data?.me.flair__Employee_Language__c;

  useEffect(() => {
    if (
      employeeLanguage &&
      !isEqualAlphanumerically(employeeLanguage, locale)
    ) {
      i18n.changeLanguage(employeeLanguage);
    }
  }, [employeeLanguage, i18n, locale]);

  if (loading) return <Loading isManagerModeOn={isManagerModeOn} />;

  if (error || !data) {
    return <></>;
  }

  const firstName = data.me.flair__First_Name__c || '';
  const lastName = data.me.flair__Last_Name__c || '';

  const userInfo: UserInfo = {
    id: data.me.Id,
    avatarUrl: data.me.avatar?.urlLarge ?? null,
    coverImageUrl: data.me.coverUrl ?? data.hub?.flair__Cover_Url__c ?? null,
    startDate: data.me.flair__Start_Date__c,
    endDate: data.me.flair__End_Date__c,
    position: data.me.flair__Position__c,
    name: data.me.Name,
    firstName,
    lastName,
    logoUrl: data.me.logoUrl ?? data.hub?.flair__Company_Logo_Url__c ?? null,
    inactivityTimeout: data.hub?.flair__Inactivity_Timeout__c ?? null,
    calendarToken: data.me.calendarToken,
    email: data.me.flair__Company_Email__c || '',
    locationId: data.me.location?.Id || null,
    locationName: data.me.location?.Name || null,
    departmentName: data.me.department?.Name || null,
    managerName: data.me.manager?.Name || null,
    managerRoles: data.me.managerRoles,
    isManagerModeAvailable: isManagerModeAvailable(data.me.managerRoles),
    isHiringManager: data.hiringManager.jobs.length > 0,
    isActive: isActive(data.me),
    hasLeftCompany: hasLeftCompany(data.me),
    hasSlackEnabled: data.me.hasSlackUser,
    initials: getEmployeeInitials(data.me),
    organizationsFeatures: data.organizationInfo?.features || [],
    canChangeOwnTimeEntries:
      data.me.flair__Can_Change_Own_Time_Entries__c || false,
    organisation: data.me.Organization,
    teams: data.me.teams.map((t) => ({ id: t.Id })),
  };
  return <UserInfoContext.Provider value={userInfo} {...props} />;
};

export const useUserInfo = (): UserInfo => React.useContext(UserInfoContext);

const hasLeftCompany = (me: UserInfoQuery['me']): boolean =>
  moment(me.flair__End_Date__c).isBefore(moment(), 'day');

const getIsManagerModeOn = (): boolean => {
  const lastLoggedEmployeeId = localStorage.getItem(LAST_LOGGED_EMPLOYEE_ID);
  if (!lastLoggedEmployeeId) {
    return false;
  }
  const isManagerMode = localStorage.getItem(
    getUserStorageKey(lastLoggedEmployeeId, MANAGER_MODE_LS_KEY),
  );
  return isManagerMode === 'true';
};

const isManagerModeAvailable = (managerRoles: readonly ManagerRole[]) =>
  hasAnyRoles(managerRoles, [
    ManagerRole.DirectManager,
    ManagerRole.FeedbackManager,
    ManagerRole.AbsenceApprover,
    ManagerRole.TimeEntryApprover,
    ManagerRole.Compensation,
    ManagerRole.SeeEmployeeData,
    ManagerRole.ExpenseApprover,
  ]);

export const hasAnyRoles = (
  roles1: readonly ManagerRole[],
  roles2: readonly ManagerRole[],
) => roles1.some((curRole) => roles2.includes(curRole));
