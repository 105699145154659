import * as React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import Button from '../../../../../components/button';
import {
  DashboardHolidaysQuery,
  useDashboardHolidaysQuery,
} from '../../../__generated__/graphql';
import { useState } from 'react';
import { HolidaysModal } from './HolidaysModal';
import { FlairLabel } from '../../../../../atomic/atoms/Flairlabel/FlairLabel';
import FlairFormattedDate from '../../../../../atomic/atoms/FlairFormattedDate';
import './PublicHolidaysWidget.css';
import LoadingWidget from '../CommonComponents/LoadingWidget';
import { isToday } from 'date-fns';
import { FlairDateFormat } from '../../../../../atomic/atoms/FlairFormattedDate/types';

const i18Path = 'dashboard.publicHolidays';

const PublicHolidaysWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const { data, loading, error } = useDashboardHolidaysQuery();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  if (loading) {
    return <LoadingWidget />;
  }

  if (
    error ||
    !data ||
    (data.me.upcomingHolidays.length === 0 &&
      data.me.upcomingCalendarPeriods.length === 0)
  ) {
    return null;
  }

  const holidays = mapHolidays(data);

  const footerActions = (
    <Button
      className="p-0"
      label={t('seeAll')}
      variant="link"
      onClick={() => setIsModalVisible(true)}
    />
  );

  return (
    <>
      <FlairCard
        cardTitle={t('title')}
        footerActions={footerActions}
        contentClassName="d-flex gap-3-5 flex-column pb-3-5 pt-2-5">
        {holidays.slice(0, 3).map((h) => {
          const today = isToday(new Date(h.day));
          return (
            <div className="d-flex justify-content-between">
              <FlairLabel text={h.name} className="h4-regular holiday-name" />
              {today ? (
                t('today')
              ) : (
                <FlairFormattedDate
                  day={h.day}
                  format={FlairDateFormat.NumericDayMonth}
                  className="h5-regular holiday-date"
                />
              )}
            </div>
          );
        })}
      </FlairCard>
      <HolidaysModal
        show={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        holidays={holidays}
      />
    </>
  );
};

const mapHolidays = (data: DashboardHolidaysQuery) => {
  if (data.me.upcomingCalendarPeriods.length > 0) {
    return data.me.upcomingCalendarPeriods.map((holiday) => ({
      id: holiday.Id,
      name: holiday.Name,
      day: holiday.flair__Start_Date__c,
    }));
  }

  return data.me.upcomingHolidays.map((holiday) => ({
    id: holiday.Id,
    name: holiday.Name,
    day: holiday.flair__Day__c,
  }));
};

export default PublicHolidaysWidget;
