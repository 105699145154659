import { times } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import ServerError from '../../../../../components/ServerError';
import { CalendarDay } from '../../../components/AbsenceCalendar/types';
import {
  calcAbsencesByDays,
  calcHolidaysByDay,
  calcWorkloadsByDays,
  workloadIncludesDay,
} from '../../../components/AbsenceCalendar/Utils';
import {
  TeamCalendarQuery,
  useTeamCalendarQuery,
} from '../../../__generated__/graphql';
import Table from './Table';
import { TeamCalendarFiltersType } from './Table/TeamCalendarFilters/types';
import { split } from '../../../../../utils/string';
import Loading from './Loading';
import { specialTeams } from 'server/src/apps/helpers/TeamsFilterationHelpers';

type Props = {
  fromDate: Moment;
  filterData: TeamCalendarFiltersType;
};

export type AbsenceColleague = {
  id: string;
  name: string;
  avatarUrl: string | undefined;
  days: CalendarDay[];
};

const buildColleagues = (
  data: TeamCalendarQuery['me']['teamColleagues'],
  fromDate: Moment,
  toDate: Moment,
) => {
  const numOfDays = toDate.diff(fromDate, 'days');

  return data.map((collegue: TeamCalendarQuery['me']['teamColleagues'][0]) => {
    const absencesByDays = calcAbsencesByDays(collegue.absences2);

    const workloadsByDays = calcWorkloadsByDays(collegue.workloads, toDate);
    const holidaysByDay = calcHolidaysByDay(collegue.holidays);

    return {
      id: collegue.Id,
      name: collegue.Name,
      avatarUrl: collegue.avatar?.url ?? undefined,
      days: times(numOfDays + 1, (i) => {
        const day = moment(fromDate).add(i, 'days');
        const absences = absencesByDays.get(day.format('YYYYMMDD')) || [];
        const workload = workloadsByDays.get(day.format('YYYYMMDD'));
        const holiday = holidaysByDay.get(day.format('YYYYMMDD'));

        return {
          day: day,
          absences: absences,
          workday: (workload && workloadIncludesDay(workload, day)) || false,
          hover: false,
          holiday: holiday,
        };
      }),
    };
  });
};

const Content: React.FC<Props> = ({ fromDate, filterData }) => {
  const toDate = fromDate.clone().endOf('month');

  const teams = split(filterData.teams);

  const { data, loading, error } = useTeamCalendarQuery({
    variables: {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
      locations: split(filterData.locations),
      teams: teams.length > 0 ? teams : [specialTeams.all_teams],
      departments: split(filterData.departments),
    },
  });

  if (loading) return <Loading />;

  if (error || data === undefined) return <ServerError />;

  const colleagues: AbsenceColleague[] = buildColleagues(
    data.me.teamColleagues,
    fromDate,
    toDate,
  );

  return (
    <Table
      fromDate={fromDate}
      toDate={toDate}
      colleagues={colleagues}
      filterData={filterData}
    />
  );
};

export default Content;
