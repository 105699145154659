import * as React from 'react';
import {
  WorkflowItemSignatureRequest,
  WorkflowItemStatus,
} from '../../WorkflowItems/types';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { useState } from 'react';
import {
  SignRequestSignerStatus,
  useUpdateWorkflowItemRegularMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
} from '../../../../__generated__/graphql';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { ModalSidebarContent } from '../../../../components/ModalSidebar';
import { WorkflowItemCommonFields } from '../WorkflowItemCommonFields';
import { FormGroup } from '../../../../../../components/form/FormGroup';
import LightSectionContainer from '../LightSectionContainer';
import Button from '../../../../../../components/button';
import { WorkflowItemStatusAutoSave } from '../WorkflowItemStatusAutoSave';
import { Spinner } from 'react-bootstrap';

type Props = {
  workflowItem: WorkflowItemSignatureRequest;
};

const WorkflowItemSignatureForm: React.FC<Props> = ({ workflowItem }) => {
  const { readonly } = workflowItem;
  const t = useNamespacedTranslation(
    'performanceReview.workflowsItems.sidebar',
  );

  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);

  const [updateItem] = useUpdateWorkflowItemRegularMutation();

  const errorHandler = useMutationErrorHandler();

  const isSigned =
    workflowItem.signRequestSignerStatus === SignRequestSignerStatus.Signed;

  const isProcessing = !isSigned && !workflowItem.externalSignatureLink;

  const handleStatusChange = (status: WorkflowItemStatus) => {
    setIsStatusLoading(true);
    updateItem({
      variables: {
        input: {
          id: workflowItem.id,
          status: status as WorkflowItemStatusGenerated,
        },
      },
    })
      .then(() => {})
      .catch(errorHandler)
      .finally(() => {
        setIsStatusLoading(false);
      });
  };

  const handleOpenSignatureLink = () => {
    if (workflowItem.externalSignatureLink) {
      window.open(workflowItem.externalSignatureLink, '_blank');
    }
  };

  const onStatusLoading = (isStatusLoading: boolean) => {
    setIsStatusLoading(isStatusLoading);
  };

  return (
    <ModalSidebarContent
      body={
        <>
          <WorkflowItemCommonFields
            workflowItem={workflowItem}
            onStatusLoading={onStatusLoading}
          />
          <FormGroup>
            {!readonly && (
              <LightSectionContainer>
                {isProcessing ? (
                  <div>
                    <Spinner size="sm" className="me-2"></Spinner>
                    {t('theLinkIsBeingGenerated')}
                  </div>
                ) : (
                  <Button
                    label={t(isSigned ? 'documentSigned' : 'signDocument')}
                    className="w-100"
                    variant="outline-primary"
                    onClick={handleOpenSignatureLink}
                    disabled={isSigned}
                  />
                )}
              </LightSectionContainer>
            )}
          </FormGroup>
          <WorkflowItemStatusAutoSave
            value={workflowItem.status}
            onChange={handleStatusChange}
            readonly={workflowItem.readonly}
            saving={isStatusLoading}
            saved={!isStatusLoading}
          />
        </>
      }
    />
  );
};

export default WorkflowItemSignatureForm;
