import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Column, Row, SortingRule } from 'react-table';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import { EmployeeCell } from '../Common/EmployeeCell';
import { WorkflowEmployee, WorkflowStep, WorkflowTeam } from '../types';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  WorkflowItemStatus,
  WorkflowItemStatusInfo,
  WorkflowItemTableItem,
} from './types';
import { compareInt, employeeSort } from '../Common/sorting';
import { ResponsibleCell } from '../Common/ResponsibleCell';
import { WorkflowItemStatusCell } from './WorkflowItemStatusCell';
import styled from '@emotion/styled';
import CommentCount from '../../../components/Comment/CommentCount/CommentCount';
import { TableContentManual } from '../../../manager/components/TableContentManual';
import { WorkflowItemDueDateCell } from './WorkflowItemDueDateCell';
import { WorkflowItemNameCell } from './WorkflowItemNameCell';

export type Item = {
  id: string;
  showComments?: boolean;
};

export type Props<T> = {
  employeeColumnVisible: boolean;
  workflowItems: WorkflowItemTableItem[];
  onItemClick: (item: Item) => void;
  onStatusChange: (info: WorkflowItemStatusInfo) => void;
  updatingItems: WorkflowItemStatusInfo[];
  initialSortBy?: Array<SortingRule<T>>;
  onSortByChanged?: (sortBy: SortingRule<T>[]) => void;
  pageSize?: number;
  onPageIndexChanged?: (pageIndex: number) => void;
  itemsCount?: number | undefined;
  initialPageIndex?: number;
};

export type TableWorkflowItem = {
  id: string;
  name: string;
  workflowName: string;
  employee: WorkflowEmployee;
  responsible: WorkflowEmployee | WorkflowTeam | null;
  dueDate: string | null;
  status: WorkflowItemStatus;
  readonly: boolean;
  updatingToStatus: WorkflowItemStatus | null;
  isMandatory: boolean;
  isStepActive: boolean;
  workflowStep: WorkflowStep;
  commentsCount: number;
};

export const WorkflowItemsTable: React.FC<Props<TableWorkflowItem>> = ({
  workflowItems,
  employeeColumnVisible,
  onItemClick,
  onStatusChange,
  updatingItems,
  pageSize,
  itemsCount,
  initialPageIndex,
  onPageIndexChanged,
  onSortByChanged,
  initialSortBy,
}) => {
  const { t } = useTranslation();

  const items = workflowItems.map((x) =>
    mapToWorkflowTableItem(x, updatingItems),
  );

  const columns: Column<TableWorkflowItem>[] = useMemo(() => {
    const columnsBeforeFilter: Column<TableWorkflowItem>[] = [
      {
        Header: () => (
          <Trans t={t} i18nKey="workflows.workflowItems.table.header.task" />
        ),
        accessor: 'name',
        Cell: ({
          value,
          row,
        }: {
          value: string;
          row: Row<TableWorkflowItem>;
        }) => {
          return (
            <WorkflowItemNameContainer title={value} className="text-truncate">
              <WorkflowItemNameCell
                name={value}
                onItemClick={onItemClick}
                rowId={row.original.id}
                isMandatory={row.original.isMandatory}
                workflowStep={row.original.workflowStep}
              />
            </WorkflowItemNameContainer>
          );
        },
      },
      {
        Header: () => (
          <Trans
            t={t}
            i18nKey="workflows.workflowItems.table.header.workflow"
          />
        ),
        accessor: 'workflowName',
      },
      {
        Header: () => (
          <Trans
            t={t}
            i18nKey="workflows.workflowItems.table.header.workflowAssignee"
          />
        ),
        accessor: 'employee',
        Cell: ({ value }: { value: WorkflowEmployee }) => {
          return (
            <EmployeeCell
              name={value.name}
              avatarUrl={value.avatarUrl}
              position={value.position ?? undefined}
              department={value.department ?? undefined}
            />
          );
        },
        sortType: (
          rowA: Row<TableWorkflowItem>,
          rowB: Row<TableWorkflowItem>,
        ) => employeeSort(rowA.original.employee, rowB.original.employee),
      },
      {
        Header: () => (
          <Trans
            t={t}
            i18nKey="workflows.workflowItems.table.header.responsible"
          />
        ),
        accessor: 'responsible',
        Cell: ({ value }: { value: TableWorkflowItem['responsible'] }) => {
          return <ResponsibleCell value={value} />;
        },
        sortType: responsibleSort,
      },
      {
        Header: () => (
          <Trans
            t={t}
            i18nKey="workflows.workflowItems.table.header.comments"
          />
        ),
        accessor: 'commentsCount',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <CommentCount
            count={value}
            onClick={() =>
              onItemClick({ id: row.original.id, showComments: true })
            }
          />
        ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="workflows.workflowItems.table.header.dueDate" />
        ),
        accessor: 'dueDate',
        Cell: ({ value }: { value: string | null }) => (
          <WorkflowItemDueDateCell dueDate={value} />
        ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="workflows.workflowItems.table.header.status" />
        ),
        accessor: 'status',
        Cell: ({
          value,
          row,
        }: {
          value: WorkflowItemStatus;
          row: Row<TableWorkflowItem>;
        }) => {
          const readonly = row.original.readonly;
          const id = row.original.id;
          const statusInfo = { workflowItemId: id, status: value };
          const updatingToStatus = row.original.updatingToStatus;
          return (
            <WorkflowItemStatusCell
              value={statusInfo}
              readonly={readonly}
              onChange={onStatusChange}
              updatingToStatus={updatingToStatus}
              isStepActive={row.original.workflowStep.isActive}
            />
          );
        },
      },
    ];
    return columnsBeforeFilter.filter(
      (col): col is Column<TableWorkflowItem> => {
        if (col.accessor === 'employee') {
          return employeeColumnVisible;
        }
        return true;
      },
    );
  }, [t, employeeColumnVisible, onItemClick, onStatusChange]);
  if (!items.length) {
    return <EmptyWorkflowItems />;
  }

  return (
    <TableContentManual
      columns={columns}
      data={items}
      pageSize={pageSize}
      itemsCount={itemsCount}
      initialSortBy={initialSortBy}
      onSortByChanged={onSortByChanged}
      initialPageIndex={initialPageIndex}
      onPageIndexChanged={onPageIndexChanged}
    />
  );
};

const EmptyWorkflowItems: React.FC = () => {
  const t = useNamespacedTranslation('workflows.workflowItems.table');
  return <EmptyStateCardBody title={t('empty')} />;
};

export function mapToWorkflowTableItem(
  src: WorkflowItemTableItem,
  updatingItems: WorkflowItemStatusInfo[],
): TableWorkflowItem {
  const updatingToStatus =
    updatingItems.find((x) => x.workflowItemId === src.id)?.status ?? null;
  return {
    id: src.id,
    name: src.name,
    readonly: src.readonly,
    workflowName: src.workflowName,
    employee: src.employee,
    responsible: src.responsible,
    dueDate: src.dueDate,
    isMandatory: src.isMandatory,
    status: src.status,
    updatingToStatus,
    isStepActive: src.workflowStep.isActive,
    workflowStep: src.workflowStep,
    commentsCount: src.commentsCount,
  };
}

export const responsibleSort = (
  rowA: Row<TableWorkflowItem>,
  rowB: Row<TableWorkflowItem>,
) => {
  const a = rowA.original.responsible;
  const b = rowB.original.responsible;

  const aWeight = getResponsibleSortWeight(a);
  const bWeight = getResponsibleSortWeight(b);
  const compareWeightResult = compareInt(aWeight, bWeight);
  if (compareWeightResult !== 0) {
    return compareWeightResult;
  }
  return String(a?.name).localeCompare(String(b?.name));
};

const getResponsibleSortWeight = (
  responsible: TableWorkflowItem['responsible'],
): number => {
  if (responsible === null) {
    return 1;
  }
  return 0;
};

const WorkflowItemNameContainer = styled.div({
  maxWidth: '270px',
});
