type Employee = {
  Id: string;
  flair__Manager__c: string | null;
};

export const specialTeams = {
  my_team: 'my_team',
  all_teams: 'all_teams',
  subordinates: 'subordinates',
} as const;

export type SpecialTeam = (typeof specialTeams)[keyof typeof specialTeams];
const allSpecialTeamsOptions: string[] = [
  'my_team',
  'all_teams',
  'subordinates',
];

export const removeSpecialTeamsOptions = (teamsOptions: string[]): string[] => {
  return teamsOptions.filter(
    (teamId) => !allSpecialTeamsOptions.includes(teamId),
  );
};

export const getSpecialTeamsOptionsFlags = (teamsOptions: string[]) => {
  return {
    isAllTeams: teamsOptions.some((t) => t === 'all_teams'),
    isMyTeam: teamsOptions.some((t) => t === 'my_team'),
    isSubordinates: teamsOptions.some((t) => t === 'subordinates'),
  };
};

// it includes subordinates
export const inMyTeam = (currentEmployee: Employee, employee: Employee) => {
  return (
    employee.Id === currentEmployee.Id || // me
    (currentEmployee.flair__Manager__c &&
      employee.flair__Manager__c === currentEmployee.flair__Manager__c) || // I and the employee have the same manager
    (currentEmployee.flair__Manager__c &&
      employee.Id === currentEmployee.flair__Manager__c) || // The employee is my manager
    employee.flair__Manager__c === currentEmployee.Id // the employee that I'm his manager
  );
};

export const inSubordinates = (
  currentEmployee: Employee,
  employee: Employee,
) => {
  return (
    employee.Id === currentEmployee.Id || // me
    employee.flair__Manager__c === currentEmployee.Id // the employee that I'm his manager
  );
};
