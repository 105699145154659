import { useUserInfoQuery } from '../apps/dashboard/__generated__/graphql';

export type ServerFeatureName =
  | 'Absences'
  | 'Announcements'
  | 'Assessments'
  | 'Certificates'
  | 'Cheers'
  | 'Corporate_Documents'
  | 'Directory'
  | 'Expenses'
  | 'Goals'
  | 'Home_Absent_Today'
  | 'Home_Birthdays_Card'
  | 'Home_New_Hires_Card'
  | 'Org_Chart'
  | 'Personal_Data'
  | 'Personal_Documents'
  | 'Private_Notes'
  | 'Projects'
  | 'Recruiting'
  | 'Shift_Planner'
  | 'Skills'
  | 'Surveys'
  | 'Time_Tracking'
  | 'Ticketing'
  | 'Workflows'
  | 'HR_HelpDesk'
  | 'Inventory'
  | 'Anniversaries'
  | 'Public_Holidays';

export const useServerFeature = (featureName: ServerFeatureName) => {
  const userInfo = useUserInfoQuery();
  const hubFeatures = userInfo.data?.employeeHubFeatures;

  return isFeatureVisible(featureName, hubFeatures);
};

export const useServerFeatures = () => {
  const userInfo = useUserInfoQuery();
  const hubFeatures = userInfo.data?.employeeHubFeatures;

  return (featureName: ServerFeatureName) => {
    return isFeatureVisible(featureName, hubFeatures);
  };
};

// By default feature is visible
function isFeatureVisible(featureName: string, features: any): boolean {
  const hideFeatureFieldName = createHideFeatureFieldName(featureName);
  return !features ? true : features[hideFeatureFieldName] !== true;
}

const createHideFeatureFieldName = (featureName: string) =>
  `flair__Hide_${featureName}__c`;
