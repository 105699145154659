import {
  SignRequestSignerStatus,
  WorkflowItemFileFormat,
  WorkflowSubTask as GraphqlWorkflowSubTask,
} from '../../../__generated__/graphql';
import { WorkflowEmployee, WorkflowStep, WorkflowTeam } from '../types';
import { RecordedLoomVideo } from '../../../components/LoomVideo';
import { Maybe } from '../../../../../utils/maybe';
import { DocumentFile } from '../../Documents2';

export type WorkflowItemBase = {
  id: string;
  name: string;
  description: string | null;
  readonly: boolean;
  attachments: DocumentFile[];
  workflowId: string;
  workflowName: string;
  workflowTemplateId: string | null;
  itemTemplateId: string | null;
  isMandatory: boolean;
  status: WorkflowItemStatus;
  dueDate: string | null;
  employee: WorkflowEmployee;
  responsible: WorkflowEmployee | WorkflowTeam | null;
  instructionsLoomUrl: Maybe<string>;
  subTasks: WorkflowSubTask[];
  workflowStep: WorkflowStep;
  commentsCount: number;
};

export type WorkflowResponsibleEmployeeItem = {
  id: string;
  name: string;
};

export type WorkflowItemRegular = WorkflowItemBase & {
  itemType: 'REGULAR';
};

export type WorkflowItemDataRequest = WorkflowItemBase & {
  itemType: 'DATA_REQUEST';
  dataFieldNames: string[];
};

export type WorkflowItemFileRequest = WorkflowItemBase & {
  itemType: 'FILE_REQUEST';
  fileCategoryId: string;
  fileFormat: WorkflowItemFileFormat;
};

export type WorkflowItemCycleAssignment = WorkflowItemBase & {
  itemType: 'CYCLE_ASSIGNMENT';
};

export type WorkflowItemEvaluationRequest = WorkflowItemBase & {
  itemType: 'EVALUATION_REQUEST';
  evaluationId: string | null;
};

export type WorkflowItemVideoRequest = WorkflowItemBase & {
  itemType: 'VIDEO_REQUEST';
  loomVideo: Maybe<RecordedLoomVideo>;
  loomVideoId: Maybe<string>;
};

export type WorkflowItemSignatureRequest = WorkflowItemBase & {
  itemType: 'SIGNATURE';
  externalSignatureLink: Maybe<string>;
  signRequestSignerStatus: SignRequestSignerStatus;
};

export type WorkflowItem =
  | WorkflowItemRegular
  | WorkflowItemDataRequest
  | WorkflowItemFileRequest
  | WorkflowItemCycleAssignment
  | WorkflowItemEvaluationRequest
  | WorkflowItemVideoRequest
  | WorkflowItemSignatureRequest;

export type WorkflowItemTableItem = WorkflowItem;

export type WorkflowSubTask = Omit<GraphqlWorkflowSubTask, '__typename'>;

// todo: remove use graphql
export type WorkflowItemStatus =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'PENDING_APPROVAL'
  | 'COMPLETED'
  | 'REJECTED';

export const allWorkflowItemStatuses: WorkflowItemStatus[] = [
  'NOT_STARTED',
  'IN_PROGRESS',
  'PENDING_APPROVAL',
  'REJECTED',
  'COMPLETED',
];

export const supportedWorkflowItemStatuses: WorkflowItemStatus[] = [
  'NOT_STARTED',
  'IN_PROGRESS',
  'COMPLETED',
];

export type WorkflowItemStatusInfo = {
  workflowItemId: string;
  status: WorkflowItemStatus;
};

export const isWorkflowItemStatus = (
  value: string | null,
): value is WorkflowItemStatus => {
  return allWorkflowItemStatuses.some((x) => x === value);
};
