import React, { useContext } from 'react';
import { AllowanceInfo, PeriodType } from '../types';
import { Trans, useTranslation } from 'react-i18next';
import AbsenceHint from './AbsenceHint';
import styled from '@emotion/styled';
import { CategoryAccordionBodyContext } from '../CategoryAccordionBodyContext';
import { useHourlyAbsenceAmountFormatter } from '../../../../../hooks/useHourlyAbsenceAmountFormatter';

type Props = {
  periodType: PeriodType;
  allowances: AllowanceInfo[];
};

const i18nKey = 'absences.cards.accruedHint';

export const AccruedHint: React.FC<Props> = (props) => {
  return <AbsenceHint text={<AllowancesList {...props} />} />;
};

const AllowancesList: React.FC<Props> = ({ allowances, periodType }) => {
  const { t } = useTranslation();
  const { isHourly } = useContext(CategoryAccordionBodyContext);
  const hourlyFormatter = useHourlyAbsenceAmountFormatter();

  const getTranslationKey = () => {
    let result =
      periodType === 'next' ? `${i18nKey}.future_info` : `${i18nKey}.info`;
    if (isHourly) {
      result += '_hourly';
    }
    return result;
  };

  return (
    <StyledList className="ps-3">
      {allowances.map((item) => (
        <li key={item.absenceAllowanceId}>
          <Trans
            t={t}
            i18nKey={getTranslationKey()}
            values={{
              title: item.title,
              count: isHourly ? hourlyFormatter(item.amount) : item.amount,
            }}
            components={[<strong />]}
          />
        </li>
      ))}
    </StyledList>
  );
};

const StyledList = styled.ul`
  list-style-type: none;
`;
