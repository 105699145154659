import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isToday } from 'date-fns';
import FlairFormattedDate from '../../../../../atomic/atoms/FlairFormattedDate';
import { FlairDateFormat } from '../../../../../atomic/atoms/FlairFormattedDate/types';

type Props = {
  onClose: () => void;
  holidays: { id: string; name: string; day: string }[];
  show: boolean;
};

export const HolidaysModal: React.FC<Props> = ({ onClose, holidays, show }) => {
  const { t } = useTranslation();

  return (
    <Modal
      scrollable={true}
      enforceFocus={false}
      show={show}
      centered
      onHide={onClose}
      contentClassName="border"
      size="lg"
      header={t('home.holidaysCard.title')}>
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{t('home.holidaysCard.title')}</h4>
      </Modal.Header>
      <Table size="sm" className="card-table" responsive>
        <tbody>
          {holidays.map((c) => {
            const today = isToday(new Date(c.day));
            return (
              <tr key={c.id}>
                <td>{c.name}</td>
                <td className="text-end">
                  {today ? (
                    t('today')
                  ) : (
                    <span className="align-middle">
                      <FlairFormattedDate
                        day={c.day}
                        format={FlairDateFormat.NumericDayMonth}
                      />
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Modal>
  );
};
